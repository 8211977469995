import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useContext, useEffect } from 'react';
import { theme } from '../Theming/Theming';

export const useCoreTrek = selectedChannel => {
  const coreTrek = theme.config.coreTrek;
  if (!coreTrek) return null;

  if (
    coreTrek &&
    typeof coreTrek === 'object' &&
    typeof coreTrek[selectedChannel.name] !== 'undefined'
  ) {
    return coreTrek[selectedChannel.name];
  }

  return null;
};

const CoreTrekWidget = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const coreTrek = useCoreTrek(selectedChannel);

  useEffect(() => {
    if (!coreTrek) return () => {};
    const { assistantId, apiKey } = coreTrek;
    if (!(assistantId && apiKey)) return () => {};

    // Lägg till scriptet dynamiskt
    (function(w, d, s, o, f, js, fjs) {
      w['CoreTrekAiWidget'] = o;
      w[o] =
        w[o] ||
        function() {
          (w[o].q = w[o].q || []).push(arguments);
        };
      js = d.createElement(s);
      fjs = d.getElementsByTagName(s)[0];
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
    })(window, document, 'script', 'ctw', 'https://widget.coreai.no/widget.js');

    // Starta widgeten när skriptet är laddat
    if (window.ctw) {
      window.ctw('start', {
        assistantId,
        userToken:
          typeof window.coreAiUserToken !== 'undefined'
            ? window.coreAiUserToken
            : '',
        apiKey
      });
    }
  }, [coreTrek]);
  return null;
};

export default CoreTrekWidget;
